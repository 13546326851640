body::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for WebKit browsers */
}

.btn-pink {
  background-color: #900000 !important;
  color: white !important;
  border-radius: 5px !important;
}

.btn-pink:hover {
  background-color: #900000e1 !important;
  color: white !important;
}

.navbar-brand img {
  max-height: 50px;
}

.navbars {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  ;
}

@media (min-width:1px) and (max-width:1023px) {
  /* .nav-button-width {
    display: flex !important;
    justify-content: center !important;
  } */
}

@media (min-width:1024px) and (max-width:1338px) {
  .nav-button-width {
    width: 90px !important;
    font-size: 12px !important;
  }

  .navbar-name {
    font-size: 14px !important;
  }
}

/*navbar Finish*/

.video-container {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: -100px;
}

/* @media(min-width:426px) and (max-width:768px) {
  .video-container {

    margin-top: -50px;
  }
}

@media(min-width:1px) and (max-width:425px) {
  .video-container {

    margin-top: -50px;
  }
} */

.video-container video {
  width: 100% !important;
}

.home-logo-video {
  width: 183px;
}

@media (min-width: 1024px) and (max-width: 1339px) {
  .home-logo-video {
    width: 103px;
  }

  .video-welcome {
    margin-top: 15px;
    font-size: 25px;
    color: rgb(251, 242, 6);
  }

  .video-company-name {
    margin-top: 1px;
    font-size: 50px;
    color: rgb(251, 242, 6);
  }

  .video-para {
    margin-top: 15px;
    font-size: 15px;
    line-height: 28px;
  }

  .videos-center {
    margin-top: 60px !important;
  }
}

.videos-center {
  position: absolute;
  top: 30%;
  left: 40%;
  transform: translate(-10%, -60%);
  text-align: center;
  color: white;
}

@media (min-width: 1335px) and (max-width: 2560px) {
  .home-logo-video {
    width: 130px;
  }

  .video-welcome {
    margin-top: 20px;
    font-size: 25px;
    color: rgb(251, 242, 6);
  }

  .video-company-name {
    margin-top: 20px;
    font-size: 50px;
    color: rgb(251, 242, 6);
  }

  .video-para {
    margin-top: 15px;
    font-size: 15px;
    line-height: 28px;
  }

  .videos-center {
    margin-top: 100px !important;
  }
}

@media (min-width: 1335px) and (max-width: 2560px) {
  .video-container {
    margin-top: -50px;
  }

  .videos-center {
    width: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 1024px) and (max-width: 1339px) {
  .video-container {
    margin-top: -50px;
  }

  .videos-center {
    width: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}

@media (min-width: 768px) and (max-width: 1023px) {
  .video-container {
    margin-top: -50px;
  }

  .videos-center {
    width: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .home-logo-video {
    width: 93px;
  }

  .video-welcome {
    margin-top: 0px;
    font-size: 22px;
    color: rgb(251, 242, 6);
  }

  .video-company-name {
    margin-top: 0px;
    font-size: 35px;
    color: rgb(251, 242, 6);
  }

  .video-para {
    margin-top: 1px;
    font-size: 15px;
    line-height: 22px;
  }

  .videos-center {
    margin-top: 60px !important;
  }
}

@media (min-width: 1px) and (max-width: 767px) {
  .video-container {
    margin-top: -50px;
  }

  .videos-center {
    width: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .home-logo-video {
    width: 30px;
  }

  .video-welcome {
    margin-top: 0px;
    font-size: 12px;
    color: rgb(251, 242, 6);
  }

  .video-company-name {
    margin-top: -13px;
    font-size: 18px;
    color: rgb(251, 242, 6);
  }

  .video-para {
    margin-top: -10px;
    font-size: 5px;
    line-height: 8px;
  }

  .videos-center {
    margin-top: 40px !important;
  }
}

.homebannerdiscount {
  position: absolute;
  bottom: 5px;
  right: 10px;
  text-align: right;
}

.homediscount {
  max-width: 390px;
  animation: blink 2s infinite;
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

@media(min-width:1px) and (max-width:375px) {
  .navbar-name {
    font-size: 15px;
  }
}

/* Apply the animation to the discount image */


.content {
  position: absolute;
  bottom: 10px;
  left: 10%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
}

.giftimg {
  max-width: 1200px;
  width: 32%;
  height: auto;
}

.saravedi {
  position: absolute;
  top: 10px;
  right: 100px;
  text-align: right;
  width: 100%;
}

.saravediimg {
  max-width: 800px;
  width: 20%;
  height: auto;
  transform-origin: 50% 50%;
  animation: circular-swing 14s infinite ease-in-out;
}

@keyframes circular-swing {
  0% {
    transform: translateX(0) rotate(0deg);
  }

  25% {
    transform: translateX(-30px) rotate(20deg);
  }

  50% {
    transform: translateX(0) rotate(0deg);
  }

  75% {
    transform: translateX(30px) rotate(20deg);
  }

}

@media (min-width:425px) and (max-width: 767px) {
  .homebannerdiscount {
    bottom: 10px;
    right: 10px;
  }

  .homediscount {
    margin-left: 110px;
    width: 120px !important;
    animation: blink 2s infinite;
  }
}

@media (min-width:375px) and (max-width: 424px) {
  .homebannerdiscount {
    bottom: 10px;
    right: 10px;
  }

  .homediscount {
    margin-left: 110px;
    width: 120px !important;
    animation: blink 2s infinite;
  }
}

@media (min-width:1px) and (max-width: 374px) {
  .homebannerdiscount {
    bottom: 10px;
    right: 10px;
  }

  .homediscount {
    margin-left: 145px;
    width: 120px !important;
    animation: blink 2s infinite;
  }
}

@media (max-width: 320px) {
  .homediscount {
    width: 80px !important;
    animation: blink 2s infinite;
  }

  .videomt {
    margin-top: 50px;
  }
}

@media (min-width:1024px) and (max-width: 1439px) {
  .homebannerdiscount {
    bottom: 10px;
    right: 10px;
  }

  .homediscount {
    margin-left: 135px;
    width: 205px !important;
    animation: blink 2s infinite;
  }
}


@media (max-width: 768px) {
  .giftimg {
    max-width: 1200px;
    width: 35%;

  }

  .content {
    bottom: 15px;
  }

  .saravediimg {
    max-width: 800px;
    /* Adjust the size of the image */
    width: 20%;
  }

  .saravedi {
    right: 30px;
  }

  .homebannerdiscount {
    bottom: 1px;
    right: 50px;
    width: 180px !important;
  }

  .homediscount {
    max-width: 190px;
    animation: blink 2s infinite;
  }

}

.carousel-control-prev,
.carousel-control-next,
.carousel-indicators {
  display: none !important;
}


.head-content {
  background-color: rgb(251, 242, 6) !important;
  border-color: rgb(251, 242, 6) !important;
  color: #d12b1e;

}

.head-top {
  padding: 0 90px !important;
}

@media (min-width: 1px) and (max-width: 768px) {
  .head-top {
    padding: 0 !important;

  }
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.header-row {
  background-color: #b23b3b !important;
}

.header-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap !important;
  /* Adjust the gap between items as needed */
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: white;
  font-weight: bold;
}

.header-row-head {
  background-color: rgb(251, 242, 6);

}



.product-count-input,
.actual-price-input,
.discount-price-input {
  width: 90px;
  text-align: center;
  margin: 0 10px;
}

.cart-icon {
  font-size: 39px !important;
  /* background-color: orange; */
  background-color: rgb(251, 242, 6);
  /* Background color */
  color: #b23b3b;
  /* Icon color */
  padding: 7px;
  cursor: pointer;
}


.header-content .cart-icon+.badges-color {
  background-color: #891652 !important;
  color: white !important;
  /* padding: 5px 10px !important;
  border-radius: 50% !important;
  font-size: 12px !important; */

}

.subcategory-header {
  background-color: #b23b3b;
  color: white;
  text-align: center;
  font-weight: bold;
}

.custom-table th,
.custom-table td {
  padding: 8px;
  border: 1px solid black !important;
  text-align: center;
}

.header-row-head th {
  border: 1px solid #ddd !important;
}


/*table model card table start*/

.custom-table th {
  color: black;
}

.quantity-input {
  width: 60px;
  text-align: center;
}

.table-responsive {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 8px;
  border: 1px solid #ddd;
  /* Border for th and td */
  text-align: left;
}

.table th {
  background-color: #f4f4f4;
}

tbody td {
  border: 1px solid #ddd;
  font-weight: 600 !important;
  /* Ensure tbody td has a border */
}

@media (max-width: 767px) {
  .t-photo {
    display: none;
  }

  .table th,
  .table td {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  .table th {
    position: absolute;
    left: -9999px;
    top: -9999px;
  }

  .table td {
    position: relative;
    padding-left: 50%;
    text-align: right;
  }

  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 10px;
    font-weight: bold;
    text-align: left;
  }
}