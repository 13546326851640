.fotter {
    background-image: url('../assests/fotter.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
}

.fotter::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000096;
    opacity: 0.9;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;

    /* Light orange color with 50% transparency */
    z-index: -1;
}

.fotterlogo {
    width: 100px;
}

@media (min-width: 1024px) and (max-width: 1180px) {
    .fotterlogo {
        width: 65px !important
    }

    .footer-comapny-name {
        font-size: 23px !important;
    }

    .footer-comapny-names {
        font-size: 23px !important;
        margin-top: -15px;
    }
}
@media (min-width: 0px) and (max-width: 375px) {
    .fotterpara{
        font-size: 13px !important;
    }
}

.social-icon {
    background-color: #4267B2;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 10%;
    margin-right: 10px;
    display: inline-block;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.social-icon svg {
    width: 24px;
    height: 24px;
}

.fotterlast {
    background-color: #90060b;
    color: #FFFFFF;
}

.fotterpara-underline {
    text-decoration: underline !important;
}

.fotterpara {
    color: #FFFFFF;
    font-family: "Catamaran",
        Sans-serif;
    font-size: 17px;
    font-weight: 500;
}


/* bankfooter */

.box{
    border: 1px solid rgba(128, 128, 128, 0.229) !important;
}

/* bankfooter end*/