.whatsapp-icon {
    position: fixed;
    top: 350px;
    left: 20px;
    background-color: #25D366;
    color: white;
    font-size: 24px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
}

.whatsapp-icon:hover {
    color: #128C7E;
}

.about-img {
    background-image: url('../assests/why1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.about-img-banner-content {
    color: white;
}

.about-back-color {
    background-color: transparent;
    background-image: linear-gradient(180deg, #D12B1E 50%, #f5ead6 50%);
}

.aboutback-comparse {
    padding: 50px 40px 50px 80px;
}

@media (min-width:1px) and (max-width:424px) {
    .aboutback-comparse {
        padding: 50px 0px 0px 0px;
    }
}

@media (min-width:425px) and (max-width:767px) {
    .aboutback-comparse {
        padding: 50px 40px 50px 45px;
    }
}

@media (min-width:768px) and (max-width:1020px) {
    .aboutback-comparse {
        padding: 50px 5px 50px 5px;
    }

    .countupplus {
        color: #242424;
        font-family: "Mulish", Sans-serif;
        font-size: 27px;
    }
}

@media (min-width:1024px) and (max-width:1339px) {
    .aboutback-comparse {
        padding: 50px 30px 50px 30px;
    }

    .about-tops-content {}
}

.about-company {
    color: #FFFFFF;
    font-family: "Mulish", Sans-serif;
    font-size: 23px;
    font-weight: 700;
}

.about-quality {
    color: #040404;
    font-family: "Mulish", Sans-serif;
    font-size: 20px;
    font-weight: 500;
}

.aboutback-comparse-two {
    padding: 65px 40px 50px 0px;
}

.aboutback {
    background-image: url('../assests/about-us_.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.about-img-index {
    margin-top: 80px;
    width: 100%;
    text-align: center;
}

@media (min-width:1440px) and (max-width:2560px) {
    .aboutback {
        height: 500px;
    }

    .counts-about {
        margin-left: 25px !important;
    }
}

@media (min-width:1024px) and (max-width:1339px) {
    .aboutback {
        height: 500px;
    }

    .counts-about {
        margin-left: 20px !important;
    }
}

@media (min-width:768px) and (max-width:1023px) {
    .aboutback {
        height: 500px;
    }

    .counts-about {
        margin-left: 5px !important;
    }
}

@media (min-width:1px) and (max-width:767px) {
    .aboutback {
        height: 430px !important;
    }
}

.countupplus {
    color: #242424;
    font-family: "Mulish", Sans-serif;
    font-size: 33px;
    font-weight: 800;
}

.FaPlus {
    font-size: 25px;
    margin-top: -15px;
}