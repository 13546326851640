#product-table #regular-price {
  text-decoration: line-through;
  text-decoration-color: red;
  color: black;
}

.download-button {
  background-color: white;
  color: #900000;
  /* The color matches the pink text */
  border: 2px solid #900000;
  padding: 10px 20px;
  /* font-size: 16px; */
  cursor: pointer;
  border-radius: 4px;
  text-transform: uppercase;
}

.download-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.download-button:hover:not(:disabled) {
  background-color: #900000;
  color: white;
  border: 2px solid #900000;
}

/*table model card table finish*/

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.download-button {
  animation: blink 1s infinite;
  /* 1s duration, infinite loop */
}